import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue7({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="New tech, focusing on my health, tree planting and more"
            pageSocialImage=""
            pageTitle="Issue #7"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #7"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "I’m quite surprised and proud that I’ve kept this newsletter going for 7 months now – so thank you for taking the time to read it.", type: "paragraph"},
                        {spans: [], text: "Something I’ve found quite difficult as a freelancer is to keep going when you’ve hit burnout. I‘ve definitely felt the effects of burnout over the past couple of months but unlike a traditional job, you still need to bring in work and do it – you can’t just coast along as easily.", type: "paragraph"},
                        {spans: [], text: "Thankfully I’ve been doing other stuff with my time to help recharge – for me, it’s a combination of doing stuff like focusing on my health and I find working on my own projects also relights that fire.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at June"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "Work has been pretty steady this month. For the most part, I’ve been working on a NextJS contract project and I’ve also done some design work on 3 different projects for ongoing clients...", type: "paragraph"},
                        {spans: [], text: "I also took on 2 new projects this month, one being a new design project – which is to design an app for a startup in the financial industry.", type: "paragraph"},
                        {spans: [], text: "The other project is the more interesting one (at least for me). It’s a Gatsby, Prismic build for an eco-conscious team.", type: "paragraph"},
                        {spans: [], text: "This is a really exciting project for me, as it aligns very much with my own values. One of the features is eco-mode – which I’ll share more details on as I work on it.", type: "paragraph"},
                        {spans: [], text: "I’m also in talks about taking on some more contract work from Sep-Dec for a team I worked with last year. This means I’ll be pretty booked up until the end of the year which is a great place to be in as I won’t have to worry about landing any work for a while.", type: "paragraph"},
                        {spans: [], text: "⌚️ Apple watch", type: "heading4"},
                        {spans: [], text: "If you remember a couple of newsletters back, I said I had pre-ordered the new Fitbit Luxe. Well, it was meant to arrive at the end of May, and then it got pushed back even further.", type: "paragraph"},
                        {spans: [], text: "Long story short, I canceled that order and got myself the Apple watch series 6. Partly because I’m already on the Apple ecosystem and it plays much nicer with my iPhone.", type: "paragraph"},
                        {spans: [], text: "I’ve had it for 2 weeks now and it’s definitely helped me maintain balance throughout my day and make time for exercise.", type: "paragraph"},
                        {spans: [], text: "💻 Macbook Air", type: "heading4"},
                        {spans: [], text: "So this was a little out of the blue. I was holding out for the new 16” Macbook Pro, but I made the decision to buy the M1 Macbook Air.", type: "paragraph"},
                        {spans: [], text: "I’ve been without a laptop since the start of the year now, which wasn’t such a problem when we were in lockdown, but now that I’m able to go places I’ve really started to miss having a laptop.", type: "paragraph"},
                        {spans: [], text: "Since getting it less than a week ago, I can honestly say it’s great to have a laptop again.", type: "paragraph"},
                        {spans: [], text: "I’ve been able to fit a bit of work in during the evening whilst sat on the sofa or finish writing this newsletter in bed.", type: "paragraph"},
                        {spans: [], text: "I was a little bit worried going from a 15” screen to a 13” one, and when I unboxed it next to my 27” monitor I was even more worried.", type: "paragraph"},
                        {spans: [], text: "But having used it away from my monitor I can say it’s perfectly fine, a slightly bigger screen would be nice but I’m still able to get stuff done on it.", type: "paragraph"},
                        {spans: [], text: "🧘‍♂️ Meditation", type: "heading4"},
                        {spans: [], text: "I’ve done a little bit of meditation in the past but I’ve never been able to make it stick.", type: "paragraph"},
                        {spans: [], text: "Why did I start meditating in the first place? I’ve actually found it really helpful to regulate my emotions when I get stressed out.", type: "paragraph"},
                        {spans: [], text: "At the start of the month, those emotions were starting to build up again, and now a month later (it’s probably a combination of things) my head feels a lot clearer than it ever has.", type: "paragraph"},
                        {spans: [], text: "Right now I’m using a combination of the Headspace app and the Breath app on my apple watch and have had an unbroken streak for close to 2 weeks now.", type: "paragraph"},
                        {spans: [], text: "🌮 Food tracking", type: "heading4"},
                        {spans: [], text: "I’ve tried tracking my food several times over the years but with little success. With my uptake in exercise and the ability to track stuff through my Apple Watch, I decided to give it a go again.", type: "paragraph"},
                        {spans: [], text: "Why? Mostly, so I’m actually aware of what I’m putting in my body.", type: "paragraph"},
                        {spans: [], text: "Again, I’m feeling so much more alert and energetic because of this, plus it’s great to know I’m hitting my macros and getting a good amount of fiber each day.", type: "paragraph"},
                        {spans: [], text: "Whilst not a magic cure for a bad diet, I would recommend most take a multivitamin – we eat largely the same foods, and the produce we do eat isn’t as nutritious as it once was due to the soil being stripped of many of its nutrients.", type: "paragraph"},
                        {spans: [], text: "I take a multivitamin from Feel and their algae omega3 – which I’ve been taking for more than a year now and have definitely seen an improvement to my general health.", type: "paragraph"},
                        {spans: [], text: "🚶 Walking", type: "heading4"},
                        {spans: [], text: "If you do a lot of walking or plan on getting into it – I’d recommend getting the Treekly app.", type: "paragraph"},
                        {spans: [], text: "If you do 5000 steps a day over 5 days each week they’ll plant a tree for you.", type: "paragraph"},
                        {spans: [], text: "As someone who walks/cycles a lot, I’m basically getting free trees planted without having to do anything extra.", type: "paragraph"},
                        {spans: [], text: "Plus it’s a good motivator to keep yourself healthy and help the planet!", type: "paragraph"},
                        {spans: [], text: "🌳 Planting trees", type: "heading4"},
                        {spans: [], text: "On the subject of planting trees, this month I made a new commitment to my business.", type: "paragraph"},
                        {spans: [], text: "For the past 10 months, I’ve been planting trees through Ecologi to offset my own carbon footprint. But I started wondering if there was anything I could do for my business to help with the environment.", type: "paragraph"},
                        {spans: [], text: "So I decided, for every freelance project that I complete, I’ll be planting 100 trees in the name of that client. On top of this, I’m also in the process of planting trees for older clients that I’ve worked with.", type: "paragraph"},
                        {spans: [], text: "💻 Website progress", type: "heading4"},
                        {spans: [], text: "So I honestly thought this would be the month I launch my updated website. But a surplus of client work has made that goal not happen again.", type: "paragraph"},
                        {spans: [], text: "I have however made progress – I’ve dropped my pages into a Gatsby setup so in theory that is ready.", type: "paragraph"},
                        {spans: [], text: "Initially, I was going to drop the articles I’ve written on my site, but I’ve decided to keep them. This is the blocker that has made me miss the goal as I need to copy over the posts and I build up the UI components to house the content.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 June goals"
                    checkList={[
                        {complete: false, text: "🚀 Launch my new business website"},
                        {complete: true, text: "📸 Take 3 photos a week and edit them"},
                        {complete: true, text: "👨‍🍳 “Create” healthy snacks each week"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to July"
                    content={[
                        {spans: [], text: "Unlike most months, I don’t really have much to report that’s different from what I’ve done this month. Because I have a longer-term project, July is going to look very much like June – contract work, some design work, and carrying on with the eco-focused project.", type: "paragraph"},
                        {spans: [], text: "I like the flexibility of working on different projects each month but it’s also good to know that you have work booked in advance and not have to worry so much about landing new work.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 July goals"
                    checkList={[
                        {complete: false, text: "✍️ Copy blog posts over to my new site"},
                        {complete: false, text: "🎯 Hit my activity targets every day"},
                        {complete: false, text: "📏 Continue tracking my food"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Keep a good amount of savings, this helps to reduce anxiety when it comes to landing new work and gives you more leverage for taking on exciting work rather than anything that comes your way."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/e75dc225-49bb-44be-88a3-e4d14232e0b2_jun-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £6.1k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/13511550-eb76-4c8d-9fd6-831c69254bc1_apple-watch.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Apple Watch Series 6", type: "heading4"},
                        {spans: [], text: "2 weeks in and I’ve got to say this has been one of my best purchases of recent. Would I recommend you get one? Yes and no. It really comes down to what your use case is, I’ve found it really useful because I use it for the health side of things and this just makes it easier to track how I’m doing. If I were not like this already I’m not sure I’d get the same benefit from the watch.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.apple.com/uk/watch/",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/8ea29035-1d22-417f-8499-bd0fe0301d37_book-why-we-sleep.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Why we sleep by Matthew Walker", type: "heading4"},
                        {spans: [], text: "I mentioned this book a couple of months back, but I’ve been reading more of it. It’s quite eye-opening how important sleep REALLY is for the human body. Simply sleeping an hour or two less each night for a week has detrimental effects on your health.", type: "paragraph"},
                        {spans: [], text: "Long story short – don’t skimp on your sleep if you want to live a long, healthy life (since tracking my sleep, I’m getting on average just shy of 9 hours of sleep a night). Ultimately you should sleep until you don’t feel tired but ~8 hours is said to be a healthy number to aim for, once you go below, this is where you start to impact your health.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Why-We-Sleep-Science-Dreams/dp/0141983760",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue7;
